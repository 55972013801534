<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h2 class="text-center">
          2. {{ $t("wizard.step-2-title") }}:
          <template
            v-if="model && model.category && model.category.name"
            class="text-center mb-3"
          >
            {{ model.category.name }}
            <template
              v-if="model && model.subCategory && model.subCategory.name"
            >
              / {{ model.subCategory.name }}
            </template>
          </template>
        </h2>

        <p class="text-muted text-center mb-3">
          {{ $t("wizard.step-2-notice") }}
        </p>
      </div>
    </div>

    <div v-if="loading" class="card-body">
      <div class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </div>
    <template v-else>
      <template v-if="selectedCategory || selectedSubCategory">
        <div class="row mb-md-2 text-center">
          <div class="col-sm-12 col-md-auto">
            <div id="tickets-table_length" class="dataTables_length">
              <!-- create template btn -->

              <b-button
                v-if="userPermissionsIDs.includes(27)"
                variant="primary"
                v-b-modal.create-template-modal
                data-toggle="modal"
                data-target="#create-template-modal"
                :title="$t('template.add')"
              >
                <i class="mdi mdi-plus-circle mr-1"></i>{{ $t("template.add") }}
              </b-button>

              <b-modal
                id="create-template-modal"
                hide-header
                hide-footer
                centered
              >
                <h4 class="text-center">{{ $t("wizard.create-template") }}</h4>
                <TemplateForm
                  modal
                  type="create"
                  :category="model.category"
                  :subcategory="model.subCategory"
                  @update="addedNewTemplate"
                />
              </b-modal>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-auto ml-auto mt-2 mt-md-0">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                {{ $t("search") }}:
                <b-form-input
                  v-model="filter"
                  type="search"
                  placeholder="..."
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>

        <div class="row" v-if="selectedTemplates.length">
          <div class="table-responsive mb-0">
            <b-table
              :items="selectedTemplates"
              :fields="tableFields"
              responsive="sm"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              class="wizard-template-table"
              striped
              small
            >
              <template #head(name)>
                {{ $t("table.name") }}
              </template>

              <template #head(metrics)>
                {{ $t("table.metrics") }}
              </template>

              <!-- metrics -->
              <template #cell(metrics)="data">
                {{ metricsToStr(data.item) }}
              </template>

              <!-- action -->
              <template #cell(action)="data">
                <div class="button-list text-nowrap">
                  <b-button
                    :variant="btnVariant(data.item.id)"
                    size="xs"
                    @click="template = data.item"
                    :title="$t('btn.select')"
                  >
                    <i class="fe-check"></i> {{ $t("btn.select") }}
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-12 col-md-8 col-lg-8 col-xl-6 mx-auto">
          <h4 class="text-center">{{ $t("wizard.create-template") }}</h4>
          <TemplateForm
            ref="templateForm"
            custom
            type="create"
            :category="{ id: 0, name: this.$t('template.custom') }"
            :subcategory="null"
            @update="addedNewTemplate"
          />
        </div>
      </template>
    </template>

    <!-- <pre>template: {{ template }}</pre> -->
    <!-- <pre>model: {{ model }}</pre> -->

    <!-- <pre>selectedCategory: {{ Number(selectedCategory) }}</pre> -->
    <!-- <pre>selectedSubCategory: {{ Number(selectedSubCategory) }}</pre> -->
    <!-- <pre>model: {{ model }}</pre> -->
    <!-- <pre>templates: {{ templates }}</pre> -->
    <!-- <pre>selectedTemplates: {{ selectedTemplates }}</pre> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateForm from "@/components/Template-form";

export default {
  components: {
    TemplateForm,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,

      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,

      tableFields: [
        {
          key: "id",
          sortable: true,
          class: "cell-id",
        },
        {
          key: "name",
          sortable: true,
          class: "cell-name",
        },
        {
          key: "metrics",
          class: "cell-metrics",
        },
        {
          key: "action",
          label: "",
          class: "cell-action",
        },
      ],

      template: null,
    };
  },
  created() {
    this.loadData();
  },
  updated() {
    // reset template
    if (this.model.template === null) {
      this.template = null;
    }
  },
  computed: {
    ...mapGetters(["templates", "userPermissionsIDs"]),
    selectedCategory() {
      return this.model.category?.id || null;
    },
    selectedSubCategory() {
      return this.model.subCategory?.id || null;
    },
    selectedTemplates() {
      // get only selected category/subcategory but NOT custom
      return this.templates?.length
        ? this.templates.filter((t) => {
            // not custom
            if (t.type !== 1) {
              if (this.selectedSubCategory) {
                // console.log("subcategory");
                if (
                  t.templateable_id === this.selectedSubCategory &&
                  t.templateable_type === "subcategory"
                ) {
                  return t;
                }
              } else {
                // console.log("category");
                if (
                  (t.templateable_id === this.selectedCategory &&
                    t.templateable_type === "category") ||
                  (t.templateable.category_id === this.selectedCategory &&
                    t.templateable_type === "subcategory")
                ) {
                  return t;
                }
              }
            }
          })
        : [];
    },
  },
  methods: {
    async loadData() {
      try {
        await Promise.all([this.$store.dispatch("fetchTemplates")]);
        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    btnVariant(id) {
      return id === this.template?.id ? "success" : "outline-success";
    },
    addedNewTemplate(template) {
      this.template = template;

      // reload list of templates for choosing one
      if (this.selectedCategory) {
        this.$store.dispatch("fetchTemplates");
      }
    },
    metricsToStr(item) {
      const metricsNames = item.metrics.map((m) => m.name);
      return metricsNames.length ? metricsNames.join(",\n") : "";
    },
  },
  watch: {
    template() {
      this.$emit("on-select", this.template);
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-template-table ::v-deep .cell-metrics {
  white-space: pre-line;
}
</style>
