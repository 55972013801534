<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h2 class="text-center">4. {{ $t("wizard.step-4-title") }}</h2>
        <p class="text-muted text-center mb-3">
          {{ $t("wizard.step-4-notice") }}
        </p>
      </div>
    </div>

    <TemplateInfo :template="template" />
  </div>
</template>

<script>
import TemplateInfo from "@/components/Template-info";

export default {
  components: {
    TemplateInfo,
  },
  props: {
    template: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped></style>
