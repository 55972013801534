<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div v-if="loading" class="card-body">
            <div class="text-center">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
          </div>
          <div v-else class="card-body">
            <form-wizard ref="wizard" color="#3bafda">
              <tab-content :before-change="validateStep_1" icon="ri-stack-line">
                <b-alert
                  v-if="step1Error"
                  variant="danger"
                  class="mt-1 text-center mx-auto col-xl-6"
                  show
                  v-html="step1Error"
                  :key="step1Error"
                />

                <Step_1
                  ref="step_1"
                  @on-select="onSelectCategory"
                  :key="refreshKey"
                />
              </tab-content>
              <tab-content
                :before-change="validateStep_2"
                icon="ri-folders-line"
              >
                <b-alert
                  v-if="step2Error"
                  variant="danger"
                  class="mt-1 text-center mx-auto col-xl-6"
                  show
                  v-html="step2Error"
                  :key="step2Error"
                />

                <Step_2
                  ref="step_2"
                  :model="finalModel"
                  :key="refreshKey"
                  @on-select="onSelectTemplate"
                />
              </tab-content>
              <tab-content
                :before-change="validateStep_3"
                icon="ri-settings-4-line"
              >
                <Step_3
                  ref="step_3"
                  :id="templateId"
                  :model="finalModel"
                  @on-update="onUpdateMetrics"
                />
              </tab-content>
              <tab-content icon="mdi mdi-checkbox-marked-circle-outline">
                <Step_4
                  ref="step_4"
                  :template="finalModel.template"
                  :key="refreshKey"
                />
              </tab-content>

              <template #footer="props">
                <template v-if="props.isLastStep">
                  <div class="wizard-footer-left">
                    <button
                      @click="resetWizard"
                      :style="props.fillButtonStyle"
                      class="wizard-btn"
                      :disabled="inprogress"
                    >
                      {{ $t("btn.create-more") }}
                    </button>
                  </div>
                  <div class="wizard-footer-right">
                    <router-link
                      :to="`/my-templates`"
                      class="btn btn-success"
                      >{{ $t("sidebar.my-templates") }}</router-link
                    >
                  </div>
                </template>
                <template v-else>
                  <div class="wizard-footer-left">
                    <button
                      @click="resetWizard"
                      :style="props.fillButtonStyle"
                      class="wizard-btn"
                      :disabled="inprogress"
                    >
                      {{ $t("btn.cancel") }}
                    </button>
                  </div>
                  <div class="wizard-footer-right">
                    <button
                      @click="props.nextTab()"
                      class="wizard-btn wizard-footer-right finish-button"
                      :style="props.fillButtonStyle"
                      :disabled="inprogress"
                    >
                      {{ $t("btn.next") }}
                    </button>
                  </div>
                </template>
              </template>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>

    <!-- <pre>finalModel: {{ finalModel }}</pre> -->
  </Layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import { FormWizard, TabContent } from "vue-form-wizard";
import Step_1 from "./step_1";
import Step_2 from "./step_2";
import Step_3 from "./step_3";
import Step_4 from "./step_4";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("wizard.title"),
    };
  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Step_1,
    Step_2,
    Step_3,
    Step_4,
  },
  data() {
    return {
      title: "wizard.title",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.wizard",
          active: true,
        },
      ],
      loading: true,
      finalModel: {},
      refreshKey: 0,
      inprogress: false,
      templateId: 0,
      metrics: [],

      step1Error: "",
      step2Error: "",
    };
  },
  created() {
    this.loading = false;
  },
  computed: {},
  methods: {
    onSelectCategory(category, subCategory) {
      this.finalModel = { ...this.finalModel, category, subCategory };
      this.finalModel.template = null;
    },
    onSelectTemplate(template) {
      this.finalModel = { ...this.finalModel, template };
    },
    onUpdateMetrics(metrics) {
      // console.log("onUpdateMetrics, metrics: ", metrics);
      this.metrics = [...metrics];
      this.refreshKey += 1;
    },

    validateStep_1() {
      // console.log("validateStep_1 ...");
      this.step1Error = "";
      this.step2Error = "";

      if (this.finalModel.category) {
        this.step1Error = "";
        this.step2Error = "";
        return true;
      }

      this.step1Error = this.$t("wizard.step-1-error");
      return false;
    },

    async validateStep_2() {
      // console.log("validateStep_2, add_template or create custom...");
      this.inprogress = true;
      this.step1Error = "";
      this.step2Error = "";

      if (this.finalModel.category?.id) {
        // if template exist - attach to user
        if (this.finalModel.template?.id) {
          try {
            const response = await axios.get(
              this.$urls.URL_USER_TEMPLATES + `/${this.finalModel.template.id}`
            );
            // console.log("1 not custom template, attach to user... response: ", response);
            this.finalModel.template = response.data.template;
            this.templateId = this.finalModel.template.id;
            return true;
          } catch (error) {
            console.log("add_template error: ", error);
          } finally {
            this.inprogress = false;
          }
        }
      } else {
        const response = await this.$refs.step_2.$refs.templateForm.handleSubmit();
        this.inprogress = false;
        this.step1Error = "";
        this.step2Error = "";
        // console.log("2 custom template, create... response: ", response);
        this.finalModel.template = response.data.template;
        this.templateId = this.finalModel.template.id;

        return true;
      }

      this.step2Error = this.$t("wizard.step-2-error");
      this.inprogress = false;

      return false;
    },
    async validateStep_3() {
      // update template with new metrics params...
      // console.log("validateStep_3, metrics: ", this.metrics);
      this.inprogress = true;

      const formData = new FormData();

      formData.append("_method", "PATCH");
      formData.append("name", this.finalModel.template.name);
      formData.append("type", this.finalModel.template.type);

      if (Number(this.finalModel.template.type) === 1) {
        formData.append("templateable_id", 0);
      } else {
        formData.append(
          "templateable_id",
          this.finalModel.template.templateable_id
        );
      }

      this.metrics.map((m) => {
        formData.append("metrics[]", JSON.stringify(m));
      });

      this.finalModel.template.metrics = this.metrics;

      // Display the key/value pairs ###debug
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + " :", pair[1]);
      // }

      try {
        const response = await axios.post(
          this.$urls.URL_TEMPLATES + `/${this.finalModel.template.id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        this.inprogress = false;
        return true;
      } catch (error) {
        console.log("Error: ", error);
        this.inprogress = false;
      } finally {
        this.$scrollToTop();
        this.refreshKey += 1;
      }

      return false;
    },
    resetWizard() {
      this.$refs.wizard.reset();
      this.finalModel = {};
      this.metrics = [];
      this.refreshKey += 1;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@keyframes fade {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade {
  animation-name: fade;
}

.wizard-tab-content {
  .wizard-tab-container {
    display: block;
    animation: fade 0.3s;
  }
}

::v-deep {
  .wizard-card-footer {
    margin-top: 6px;
  }

  // disable click on back step nav
  .wizard-nav li {
    pointer-events: none;
  }
}
</style>
