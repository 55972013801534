var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"text-center"},[_vm._v("1. "+_vm._s(_vm.$t("wizard.step-1-title")))]),_c('p',{staticClass:"text-muted text-center mb-3"},[_vm._v(" "+_vm._s(_vm.$t("wizard.step-1-notice"))+" ")]),(_vm.selected && _vm.selected.name)?_c('h4',{staticClass:"text-center mb-3"},[_vm._v(" "+_vm._s(_vm.selected.name)+" "),(_vm.selectedSub && _vm.selectedSub.name)?[_vm._v(" / "+_vm._s(_vm.selectedSub.name)+" ")]:_vm._e(),_c('a',{staticClass:"ml-1",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.resetSelect.apply(null, arguments)}}},[_vm._v("[x]")])],2):_vm._e()])]),(_vm.loading)?_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1)]):_c('div',{staticClass:"row justify-content-center"},[_vm._l((_vm.categories),function(category){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subcategories && _vm.subcategories.length === 0),expression:"subcategories && subcategories.length === 0"}],key:'category-key-' + category.id,staticClass:"col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3"},[_c('b-card',{staticClass:"category-card h-100",class:{
          selected: _vm.selected && _vm.selected.id && _vm.selected.id === category.id,
        },attrs:{"img-src":category.photo || require('@/assets/images/nophoto.png'),"img-alt":"","img-height":"200","img-top":""},on:{"click":function($event){_vm.selected = category}}},[_c('b-card-title',[_c('h5',{staticClass:"card-title text-center"},[_vm._v(_vm._s(category.name))])]),_c('b-card-text',[_vm._v(" "+_vm._s(category.description)+" ")])],1)],1)}),_vm._l((_vm.subcategories),function(subcategory){return _c('div',{key:'subcategory-key-' + subcategory.id,staticClass:"col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3"},[_c('b-card',{staticClass:"category-card h-100",class:{
          selected:
            _vm.selectedSub &&
            _vm.selectedSub.id &&
            _vm.selectedSub.id === subcategory.id,
        },attrs:{"img-src":subcategory.photo || require('@/assets/images/nophoto.png'),"img-alt":"Card image","img-height":"200","img-top":""},on:{"click":function($event){return _vm.selectSubCategory(subcategory)}}},[_c('b-card-title',[_c('h5',{staticClass:"card-title text-center"},[_vm._v(_vm._s(subcategory.name))])]),_c('b-card-text',[_vm._v(" "+_vm._s(subcategory.description)+" ")])],1)],1)}),_c('div',{staticClass:"col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3"},[_c('b-card',{staticClass:"category-card h-100",class:{
          selected: _vm.selected && _vm.selected.id === 0,
        },attrs:{"img-src":require('@/assets/images/custom.png'),"img-alt":"Card image","img-height":"200","img-top":""},on:{"click":_vm.selectCategoryCustom}},[_c('b-card-title',[_c('h5',{staticClass:"card-title text-center"},[_vm._v(_vm._s(_vm.$t("wizard.cat-custom")))])]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("wizard.create-own-template"))+" ")])],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }