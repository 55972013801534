<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h2 class="text-center">1. {{ $t("wizard.step-1-title") }}</h2>
        <p class="text-muted text-center mb-3">
          {{ $t("wizard.step-1-notice") }}
        </p>
        <h4 v-if="selected && selected.name" class="text-center mb-3">
          {{ selected.name }}
          <template v-if="selectedSub && selectedSub.name">
            / {{ selectedSub.name }}
          </template>
          <a @click.prevent="resetSelect" href="#" class="ml-1">[x]</a>
        </h4>
      </div>
    </div>

    <div v-if="loading" class="card-body">
      <div class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </div>
    <div v-else class="row justify-content-center">
      <!-- Category -->
      <div
        v-show="subcategories && subcategories.length === 0"
        v-for="category in categories"
        :key="'category-key-' + category.id"
        class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3"
      >
        <b-card
          :img-src="category.photo || require('@/assets/images/nophoto.png')"
          img-alt=""
          img-height="200"
          img-top
          class="category-card h-100"
          :class="{
            selected: selected && selected.id && selected.id === category.id,
          }"
          @click="selected = category"
        >
          <b-card-title>
            <h5 class="card-title text-center">{{ category.name }}</h5>
          </b-card-title>
          <b-card-text>
            {{ category.description }}
          </b-card-text>
        </b-card>
      </div>

      <!-- Subcategory -->
      <div
        v-for="subcategory in subcategories"
        :key="'subcategory-key-' + subcategory.id"
        class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3"
      >
        <b-card
          :img-src="subcategory.photo || require('@/assets/images/nophoto.png')"
          img-alt="Card image"
          img-height="200"
          img-top
          class="category-card h-100"
          :class="{
            selected:
              selectedSub &&
              selectedSub.id &&
              selectedSub.id === subcategory.id,
          }"
          @click="selectSubCategory(subcategory)"
        >
          <b-card-title>
            <h5 class="card-title text-center">{{ subcategory.name }}</h5>
          </b-card-title>
          <b-card-text>
            {{ subcategory.description }}
          </b-card-text>
        </b-card>
      </div>

      <!-- Custom -->
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 mb-3">
        <b-card
          :img-src="require('@/assets/images/custom.png')"
          img-alt="Card image"
          img-height="200"
          img-top
          class="category-card h-100"
          :class="{
            selected: selected && selected.id === 0,
          }"
          @click="selectCategoryCustom"
        >
          <b-card-title>
            <h5 class="card-title text-center">{{ $t("wizard.cat-custom") }}</h5>
          </b-card-title>
          <b-card-text>
            {{ $t("wizard.create-own-template") }}
          </b-card-text>
        </b-card>
      </div>
    </div>

    <!-- <pre>categories: {{ categories }}</pre> -->
    <!-- <pre>subcategories: {{ subcategories }}</pre> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      loading: true,
      selected: null,
      selectedSub: null,
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["categories"]),
    subcategories() {
      return this.selected?.subcategories || [];
    },
  },
  methods: {
    async loadData() {
      try {
        await Promise.all([this.$store.dispatch("fetchCategories")]);
        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    selectCategoryCustom() {
      // id = 1 = custom
      this.selected = { id: 0, name: this.$t("template.custom") };
      this.selectedSub = null;
    },
    selectSubCategory(subcategory) {
      this.selectedSub = subcategory;
    },
    resetSelect() {
      this.selected = null;
      this.selectedSub = null;
    },
  },
  watch: {
    selected() {
      this.$emit("on-select", this.selected, this.selectedSub);
    },
    selectedSub() {
      this.$emit("on-select", this.selected, this.selectedSub);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-card {
  cursor: pointer;
  position: relative;
  top: 0;
  box-shadow: none;
  transition: all 0.15s ease-in-out;

  & > img {
    object-fit: cover;
  }

  &:hover {
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.25);
    top: -4px;
  }

  &.selected {
    top: 0;
    box-shadow: none;
    border-color: #3bafda;
    // box-shadow: 0 0 8px 0px #3bafda;
  }
}
</style>
